import React from "react"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import GradientButton from "../components/Misc/GradientButton/gradientButton"
import EmptySpaceSeperator from "../components/Misc/EmptySpaceSeperator/emptySpaceSeperator"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const NotFoundPage = (props) => (
  <Layout locale={props.pageContext.locale} path={props.path}>
    <SEO title="404: Not found"/>

    <Container style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "500px",
    }}>
      <Row>
        <Col md={12}>
          <h1 style={{ fontSize: "70px" }} className="text-center font-weight-bold">404</h1>
          <h2 style={{ fontSize: "50px" }} className="text-center font-weight-bold">Page Not Found</h2>
          <EmptySpaceSeperator pixels={40}/>
          <div className="text-center">
            <GradientButton url="/" btnText="Back Home"/>
          </div>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage
